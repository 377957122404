<template>
  <div>
    <div class="page-body">
      <loading
        :active="loader"
        :is-full-page="true"
        :opacity="0.9"
        color="#ff6b00"
      />
      <TitleBar :title="$t('Payins')" />
      <v-container>
        <v-card flat class="border mt-6" rounded="lg">
          <v-card-title>
            <span></span>
            <v-btn
              @click="openDialog()"
              class="elevation-1"
              elevation-1
              color="primary"
              small
              ><v-icon> </v-icon>Request Payment</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-dialog v-model="paymentdialog" max-width="700px">
            <v-card class="rounded-lg">
              <div class="pt-3">
                <p class="text-center slot-icon">
                  <i class="fa fa-circle-dollar-to-slot fw-slot-icon"></i>
                </p>
                <p class="text-center font-weight-bold">
                  Create Payment Request
                </p>
              </div>
              <v-card-text>
                <v-form v-model="valid" ref="payinReq">
                  <v-col>
                    <label class="mt-0"
                      >Amount<span style="color: #f00">*</span></label
                    >
                    <v-text-field
                      :placeholder="$t('please_enter_amount')"
                      outlined
                      v-model="paymentReqBody.amount"
                      type="number"
                      :rules="sendAmtValidation"
                      hide-spin-buttons
                      required
                      dense
                      ><template v-slot:prepend-inner class="pt-0">
                        <span class="font-weight-bold mt-1 me-2">$</span>
                      </template></v-text-field
                    >
                  </v-col>
                  <v-col>
                    <v-checkbox
                      class="mb-0 text-checkbox pb-0"
                      hide-details
                      v-model="paymentReqBody.is_gst"
                      :label="$t('include_gst')"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <label
                      >Payment Method<span style="color: #f00">*</span></label
                    >
                    <v-select
                      outlined
                      v-model="paymentReqBody.payee_type"
                      :rules="FieldRequired('Payment Method')"
                      placeholder="Please select Payment Method"
                      :items="[
                        {
                          text: 'Pay Id',
                          value: 'payid',
                        },
                        {
                          text: 'BSB/Account Number',
                          value: 'bsb',
                        },
                      ]"
                      hide-spin-buttons
                      required
                      dense
                    ></v-select>
                  </v-col>
                  <v-col>
                    <label>Expires At</label>
                    <v-text-field
                      outlined
                      type="datetime-local"
                      step="2"
                      v-model="paymentReqBody.expired_at"
                      hide-spin-buttons
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <label>Remarks<span style="color: #f00">*</span></label>
                    <v-text-field
                      outlined
                      v-model="paymentReqBody.description"
                      :rules="FieldRequired('Remarks')"
                      placeholder="Please Enter Remarks"
                      hide-spin-buttons
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                </v-form>
              </v-card-text>
              <v-divider class="p-o m-0"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="paymentdialog = false" outlined>
                  {{ $t("cancel_label") }}
                </v-btn>
                <v-btn
                  @click="Addpayin()"
                  class="elevation-0"
                  color="primary"
                  >{{ $t("create_payment_link") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="successdialog" max-width="500px">
            <v-card class="rounded-lg">
              <p class="text-end mb-0">
                <v-btn
                  fab
                  icon
                  text
                  small
                  class="elevation-1 me-2 mt-2"
                  color="primary"
                  @click="successdialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </p>

              <v-card-text>
                <div class="mb-4">
                  <p class="text-center check-icon-success mb-3">
                    <i class="fa fa-check fw-check-success"></i>
                  </p>
                  <p class="text-center font-weight-bold">
                    Your Payment is Created Succesfully.
                  </p>
                  <p class="mb-0">
                    Please copy the below generated values for proceeding your
                    payment.
                  </p>
                  <v-alert v-if="istextCopied" dense small text type="success">
                    Copied successfully
                  </v-alert>
                  <div
                    v-if="resultPayid"
                    class="d-flex justify-content-center align-item-center"
                  >
                    <v-text-field
                      label="Pay Id"
                      v-model="resultPayid"
                      disabled
                      filled
                      class="ps-6 text-center"
                      hide-details
                    ></v-text-field>
                    <v-btn
                      @click="copyPayId(resultPayid)"
                      icon
                      fab
                      small
                      class="ms-2 me-4 mt-2"
                    >
                      <v-icon class="pa-2">mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                  <div
                    v-if="account_number"
                    class="d-flex justify-content-center align-item-center"
                  >
                    <v-text-field
                      label="Account Number"
                      v-model="account_number"
                      disabled
                      filled
                      class="text-center"
                      hide-details
                    ></v-text-field>
                    <v-btn
                      @click="copyPayId(account_number)"
                      icon
                      fab
                      small
                      class="ms-2 me-4 mt-2"
                    >
                      <v-icon class="pa-2">mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                  <div
                    v-if="bsb_number"
                    class="d-flex justify-content-center mt-3 align-item-center"
                  >
                    <v-text-field
                      label="BSB Number"
                      v-model="bsb_number"
                      disabled
                      filled
                      class="text-center"
                      hide-details
                    ></v-text-field>
                    <v-btn
                      @click="copyPayId(bsb_number)"
                      icon
                      fab
                      small
                      class="ms-2 me-4 mt-2"
                    >
                      <v-icon class="pa-2">mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </div></v-card-text
              >
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="headers"
            :items="payinSummery"
            class="elevation-0"
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                v-if="item.status"
                class="text-capitalize"
                small
                :color="getColor(item.status)"
                dark
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.pay_id="{ item }">
              <div class="d-flex" v-if="item.pay_id">
                <v-chip small class="mt-1 chip-styles" color="grey lighten-2">
                  <p class="mb-0">{{ item.pay_id }}</p>
                </v-chip>
                <v-btn x-small fab icon @click="copypayIdfromGrid(item.pay_id)">
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </div>
              <div class="d-flex" v-if="item.account_number">
                <v-chip small class="mt-1 chip-styles" color="grey lighten-2">
                  Account Number:
                  <p class="mb-0 font-weight-bold">{{ item.account_number }}</p>
                </v-chip>
                <v-btn
                  x-small
                  fab
                  icon
                  @click="copypayIdfromGrid(item.account_numbers)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </div>
              <div class="d-flex" v-if="item.bsb">
                <v-chip small class="mt-1 chip-styles" color="grey lighten-2">
                  BSB:
                  <p class="mb-0 font-weight-bold">{{ item.bsb }}</p>
                </v-chip>
                <v-btn x-small fab icon @click="copypayIdfromGrid(item.bsb)">
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import TitleBar from "@/components/navigations/TitleBar.vue";
import alerts from "@/mixins/alerts";
import transactionService from "../../services/TransactionService/transactionService";
import { FieldRequired } from "@/utils/validation.js";
import { validations } from "@/utils/validation";
const { sendAmtValidation } = validations;
export default {
  mixins: [alerts],
  data() {
    return {
      sendAmtValidation,
      FieldRequired,
      resultPayid: "",
      paymentdialog: false,
      successdialog: false,
      bsb_number: null,
      account_number: null,
      valid: true,
      loader: false,
      istextCopied: false,
      search: "",
      paymentReqBody: {
        is_gst: true,
        amount: "",
        expired_at: null,
        payee_type: "",
        description: "",
      },
      headers: [
        { text: "Transaction Pin", value: "transaction_pin", sortable: false },
        { text: "Payment Method", value: "payee_type", sortable: false },
        { text: "Pay Id/Account Number", value: "pay_id", sortable: false },
        { text: "Customer Details", value: "customer_name", sortable: false },
        { text: "Amount ($)", value: "amount" },
        { text: "Status", value: "status" },
        { text: "Expired at", value: "expired_at", sortable: false },
        { text: "Date", value: "created_date" },
        { text: "Remarks", value: "description" },
      ],
      payinSummery: [],
    };
  },
  components: {
    TitleBar,
    Loading,
    // EvoletKyc,
  },
  mounted() {
    this.getpayinSummary();
  },
  methods: {
    async getpayinSummary() {
      const params = {
        skip: 0,
        limit: 30000,
      };
      try {
        this.loader = true;
        const responseData = await transactionService.getpayinSummary(params);
        if (responseData.data.status_code == 200) {
          console.log(responseData.data);
          this.payinSummery = responseData.data.data;
          this.loader = false;
          // this.showAlertSuccess(responseData.data.message);
        } else {
          this.loader = false;
          this.showAlertError(responseData.data.message);
        }
      } catch (err) {
        this.loader = false;
        console.error(err);
        return;
      }
    },
    async Addpayin() {
      if (this.$refs.payinReq.validate()) {
        try {
          this.loader = true;
          if (this.paymentReqBody.expired_at) {
            const tempDateTime = this.paymentReqBody.expired_at
              .split("T")
              .join(" ");
            this.paymentReqBody.expired_at = tempDateTime;
          } else {
            this.paymentReqBody.expired_at = null;
          }

          const responseData = await transactionService.Addpayin(
            this.paymentReqBody
          );
          if (responseData.data.status_code == 200) {
            this.loader = false;
            if (this.paymentReqBody.payee_type == "bsb") {
              if (
                responseData.data &&
                responseData.data.data[0].account_number
              ) {
                this.account_number = responseData.data.data[0].account_number;
                this.bsb_number = responseData.data.data[0].bsb;
                this.paymentdialog = false;
                this.successdialog = true;
              } else {
                this.showAlertError("Account Number and BSB is not generated");
              }
            } else if (this.paymentReqBody.payee_type == "payid") {
              if (
                responseData.data &&
                responseData.data.data &&
                responseData.data.data[0].pay_id
              ) {
                this.opensuceesDialog(responseData.data.data[0].pay_id);
              } else {
                this.showAlertError("Payid is not generated");
              }
            }
            this.showAlertSuccess(responseData.data.message);
            this.getpayinSummary();
          } else {
            this.loader = false;
            this.showAlertError(responseData.data.message);
          }
        } catch (err) {
          this.loader = false;
          console.error(err);
          return;
        }
      }
    },
    openDialog() {
      this.paymentdialog = true;
      this.paymentReqBody = {
        is_gst: true,
        amount: "",
        expired_at: null,
        payee_type: "",
        description: "",
      };
      this.$refs.payinReq.resetValidation();
    },
    opensuceesDialog(pay_id) {
      this.resultPayid = pay_id;
      this.paymentdialog = false;
      this.successdialog = true;
    },
    copypayIdfromGrid(payid) {
      navigator.clipboard.writeText(payid);
      this.showAlertSuccess("copied successfully.");
    },
    copyPayId(copyValue) {
      navigator.clipboard.writeText(copyValue);
      this.istextCopied = true;
      setTimeout(() => {
        this.istextCopied = false;
      }, 1200);
    },
    getColor(status) {
      if (status == "received") return "green";
      else if (status == "pending") return "orange";
      else if (status == "expired") return "grey";
      else if (status == "void") return "red darken-2";
      else return "blue";
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.slot-icon {
  padding-top: 3px;
  font-size: 40px;
  border: 10px solid #ffe7d6;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: #fdd1b2;
  margin: auto;
}

.fw-slot-icon {
  color: white;
}
.check-icon-success {
  padding-top: 12px;
  font-size: 40px;
  font-weight: 800;
  border: 12px solid rgb(215, 255, 215);
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: rgb(71, 182, 71);
  margin: auto;
}

.fw-check-success {
  color: white;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    margin-bottom: 0px !important;
  }
}
</style>
